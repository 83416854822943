import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`DB Shoulder Press 3×12`}</p>
    <p>{`DB Rows 3×12`}</p>
    <p>{`then,`}</p>
    <p>{`21-15-9 reps each of:`}</p>
    <p>{`HSPU’s`}</p>
    <p>{`Ring Rows (Chest under rings)`}</p>
    <p>{`then,`}</p>
    <p>{`42-30-18 reps each of:`}</p>
    <p>{`Ring Pushups`}</p>
    <p>{`Calorie Row`}</p>
    <p>{`For total time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start April 8th.  Contact Eric at
fallscitystrength\\@gmail.com for more info.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      